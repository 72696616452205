.prevision-form-wrapper {
	margin: 0 20px;
}
.prevision-form-wrapper .error-msg {
	font-size: 12px;
	color: #A50203;
	height: 15px;
	margin-top: 5px;
	margin-bottom: 5px;
	margin-left: 25px;
	display: block;
}

.prevision-form-wrapper h4 {
	font-family: 'HelveticaNeueLTStd-Cn';
	font-size: 24px;
	color: #a59165;
	margin-bottom: 0;
}
.prevision-form-wrapper p {
	font-size: 12px;
	color: #707070;
	margin: 0;
}
.prevision-form-wrapper p.quantity {
	font-size: 24px;
	color: #27beb7;
	margin-top: 10px;
	margin-bottom: 20px;
}
.prevision-form-wrapper p.notes {
	margin-bottom: 40px;
}
.prevision-form-wrapper input {
	font-family: 'HelveticaNeueLTStd-Md';
	font-size: 12px;
	background-color: #2c2c2c;
	color: #828290;
	width: 100%;
	padding: 10px 25px;
	padding-bottom: 8px;
	border: 0;
	border-radius: 25px;
}
.prevision-form-wrapper button {
	font-family: 'HelveticaNeueLTStd-MdCn';
	font-size: 18px;
	color: #26b8b4;
	width: 100%;
	border: 1px solid #26b8b4;
	border-radius: 25px;
	padding-top: 8px;
	padding-bottom: 3px;
	margin-top: 20px;
	margin-bottom: 40px;
	background-color: transparent;
	cursor: pointer;
}
.prevision-form-wrapper button:hover {
	background-color: #26b8b4;
	color: #101010;
}

.prevision-form-container {
	width: 75%;
	margin: 0 auto;
}