/* Top menu */
header {
	background-color: #101010;
	position: fixed;
	width: 100%;
	*max-width: 780px;
	z-index: 30;
	top: 0;
}
header .nav-wrapper {
	padding: 10px 20px;
	display: flex;
	justify-content: space-between;
	box-sizing: border-box;
	height: 65px;
}
header .navlink-icons {
	align-self: center;
}
header .navlink-user {
	width: 30px;
	margin-right: 25px;
	display: inline-block;
	cursor: pointer;
}
header .navlink-cart {
	width: 30px;
	display: inline-block;
	position: relative;
}
header .navlink-cart span {
	background-color: #a59165;
	font-size: 10px;
	color: #ffffff;
	padding-top: 7px;
	padding-bottom: 5px;
	padding-left: 5px;
	padding-right: 5px;
	width: 15px;
	border-radius: 15px;
	text-align: center;
	left: 20px;
	top: -5px;
	position: absolute;
}
header .navlink-logo {
	width: 40px;
	display: block;
	align-self: center;
}
header .navlink-menu {
	font-family: 'HelveticaNeueLTStd-MdCn';
	color: #a59165;
	text-decoration: none;
	margin-top: 5px;
	cursor: pointer;
	align-self: center;
	display: flex;
}
header .navlink-menu span {
	align-self: center;
}
header .navlink-menu .fold-icon-wrapper {
	margin-left: 10px;
	align-self: center;
}
header .navlink-menu .fold-icon-wrapper img {
	width: 20px;
}
header .navlink-user img,
header .navlink-cart img,
header .navlink-logo img {
	width: 100%;
}

/* Drawer common */
.nav-drawer {
	position: relative;
	top: -65px;
}

/* Left drawer */
.nav-user-drawer-wrapper {
	min-height: 100vh;
	width: 100%;
	position: fixed;
}
.nav-user-drawer-bg {
	background-color: #101010;
	width: 50%;
	min-height: 100vh;
	opacity: 0.85;
	position: fixed;
}
.nav-user-drawer {
	width: 50%;
	position: fixed;
	margin-top: 10px;
	box-sizing: border-box;
}
.drawer-usericon {
	display: block;
	width: 30px;
	margin-top: 4px;
	margin-bottom: 20px;
	margin-left: 20px;
	cursor: pointer;
}
.drawer-usericon img {
	width: 100%;
}
.drawer-username {
	font-family: 'HelveticaNeueLTStd-Bd';
	color: #71bcb6;
	margin-bottom: 5px;
	margin-left: 20px;
}
.drawer-usernumber {
	font-family: 'HelveticaNeueLTStd-Roman';
	color: #737373;
	margin-bottom: 10px;
	margin-left: 20px;
}
.drawer-useredit {
	font-family: 'HelveticaNeueLTStd-Roman';
	color: #a59165;
	margin-left: 20px;
	display: block;
}
.drawer-usermenu {
	margin-top: 20px;
	padding-top: 20px;
	border-top: 1px solid #ffffff;
	padding-left: 20px;
}
.drawer-usermenu li {
	margin-bottom: 20px;
}
.drawer-usermenu li a {
	font-family: 'HelveticaNeueLTStd-MdCn';
	font-size: 22px;
	color: #a59165;
	text-decoration: none;
	cursor: pointer;
}
.drawer-usermenu li a.logout {
	color: #71bcb6;
}

/* Right drawer */
.nav-app-drawer-wrapper {
	min-height: 100vh;
	width: 100%;
	position: fixed;
}
.nav-app-drawer-bg {
	background-color: #101010;
	width: 50%;
	min-height: 100vh;
	opacity: 0.85;
	position: fixed;
	right: 0;
}
.nav-app-drawer {
	width: 50%;
	position: fixed;
	right: 0;
	margin-top: 10px;
	box-sizing: border-box;
}
.drawer-appicon {
	font-family: 'HelveticaNeueLTStd-MdCn';
	color: #a59165;
	text-decoration: none;
	padding-top: 13px;
	padding-right: 20px;
	cursor: pointer;
	display: flex;
	justify-content: flex-end;
}
.drawer-appicon span {
	align-self: center;
}
.drawer-appicon div {
	margin-left: 10px;
	align-self: center;
}
.drawer-appicon img {
	width: 17px;
}
.drawer-appmenu {
	padding-top: 30px;
	padding-right: 20px;
}
.drawer-appmenu li {
	text-align: right;
	margin-bottom: 20px;
}
.drawer-appmenu li a {
	font-family: 'HelveticaNeueLTStd-MdCn';
	font-size: 22px;
	color: #a59165;
	text-decoration: none;
}
