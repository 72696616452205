.contact-form-wrapper {
	margin: 0 20px;
}
.contact-form-wrapper .error-msg {
	font-size: 12px;
	color: #A50203;
	height: 15px;
	margin-top: 5px;
	margin-bottom: 5px;
	margin-left: 25px;
	display: block;
}

.contact-form-wrapper p {
	font-size: 14px;
	color: #ffffff;
	margin-top: 20px;
}
.contact-form-wrapper a {
	color: #26b8b4;
	text-decoration: none;
}
.contact-form-wrapper a:hover {
	color: #26b8b4;
}
.contact-form-wrapper input {
	font-family: 'HelveticaNeueLTStd-Md';
	font-size: 12px;
	background-color: #2c2c2c;
	color: #828290;
	width: 100%;
	padding: 10px 25px;
	padding-bottom: 8px;
	border: 0;
	border-radius: 25px;
}
.contact-form-wrapper button {
	font-family: 'HelveticaNeueLTStd-MdCn';
	font-size: 18px;
	color: #26b8b4;
	width: 100%;
	border: 1px solid #26b8b4;
	border-radius: 25px;
	padding-top: 8px;
	padding-bottom: 3px;
	margin-bottom: 40px;
	background-color: transparent;
	cursor: pointer;
}
.contact-form-wrapper button:hover {
	background-color: #26b8b4;
	color: #101010;
}
.contact-form-wrapper textarea {
	box-sizing: border-box;
	background-color: #101010;
	border: 1px solid #26b8b4;
	color: #707070;
	height: 150px;
	width: 100%;
	padding: 10px;
	resize: none;
}

.contact-form-wrapper .input-form-2col .input-form-col.col1 {
	width: 33%;
}
.contact-form-wrapper .input-form-2col .input-form-col.col2 {
	width: 63%;
}
.contact-form-wrapper .btn-dropdown span {
	text-align: center;
}

.contact-form-wrapper .legal-form-disclaimer {
	font-family: 'HelveticaNeueLTStd-Md';
	font-size: 12px;
	color: #707070;
	margin-top: 20px;
}
.contact-form-wrapper .legal-form-conditions input {
	width: auto;
}
.contact-form-wrapper .legal-form-conditions label {
	display: flex;
	margin-bottom: 10px;
}
.contact-form-wrapper .legal-form-conditions span {
	font-size: 14px;
	padding-top: 5px;
	padding-left: 20px;
}

.contact-form-container {
	margin-top: 40px;
}

/* Responsive */
@media screen and (max-width: 375px) {
	.contact-form-wrapper .btn-dropdown span {
		padding-left: 0;
		padding-right: 0;
	}
}