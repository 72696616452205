/* Top navigation */
.variety-nav {
  position: relative;
}
.variety-nav a {
  width: 35px;
  display: block;
  cursor: pointer;
  position: absolute;
  z-index: 20;
  top: 0;
  bottom: 0;
}
.variety-nav a.variety-nav-left {
  background: url('../../images/icon-arrow_left-s1.png') no-repeat center;
  margin-left: 20px;
}
.variety-nav a.variety-nav-right {
  background: url('../../images/icon-arrow_right-s1.png') no-repeat center;
  margin-right: 20px;
  right: 0;
}
.variety-nav p {
  font-family: 'HelveticaNeueLTStd-Bd';
  font-size: 36px;
  text-align: center;
  margin-top: 15px;
  align-self: center;
  position: absolute;
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
}
.variety-nav-controls {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  display: flex;
  justify-content: space-between;
}
.variety-nav-image {
  width: 100%;
  display: grid;
  align-self: center;
}
.variety-nav-image img {
  min-height: 70px;
  width: 100%;
  max-width: 100%;
  outline: none;
}
.variety-nav-fade {
  background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 65%, rgba(0,0,0,0.65) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 65%,rgba(0,0,0,0.65) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 65%,rgba(0,0,0,0.65) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 ); /* IE6-9 */
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

/* Slider */
.slick-slider {
  width: 100%;
  position: relative;
}
.slick-prev,
.slick-prev:hover,
.slick-prev:focus {
  background: url('../../images/icon-arrow_left-s1.png') no-repeat center;
  width: 35px;
  height: 120px;
  *top: 25%;
  left: 20px;
  z-index: 1;
}
.slick-prev:before {
  content: '';
}
.slick-next,
.slick-next:hover,
.slick-next:focus {
  background: url('../../images/icon-arrow_right-s1.png') no-repeat center;
  width: 35px;
  height: 120px;
  *top: 25%;
  right: 20px;
  z-index: 1;
}
.slick-next:before {
  content: '';
}
.slick-slide img {
  width: 100%;
}

/* LazyLoad */
.lazy-container {
  width: 100%;
  padding-bottom: 71.42%; /* 500px / 700px */
  position: relative;
  background: transparent;
}
.lazy-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
}

/* Varieties list */
.varieties-nav-list {
  display: flex;
  flex-wrap: wrap;
}
.varieties-nav-list li {
  min-width: 33.33%;
  height: 30px;
  border: 1px solid #a59165;
  box-sizing: border-box;
  overflow: hidden;
}
.varieties-nav-list li.active {
  border: 1px solid #27beb7;
}
.varieties-nav-list li a {
  font-family: 'HelveticaNeueLTStd-MdCn';
  font-size: 14px;
  color: #a59165;
  line-height: 34px;
  text-align: center;
  text-decoration: none;
  display: block;
  cursor: pointer;
  transition: 0.5s;
}
.varieties-nav-list li a:hover,
.varieties-nav-list li.active a {
  background-color: #27beb7;
  color: #ffffff;
}

/* Variety navigation */
.variety-nav-infinite {
  width: 100%;
  height: auto;
  position: relative;
}
.variety-nav-infinite .infinite-left-nav {
  width: 15px;
  position: absolute;
  left: 25px;
  top: 35%;
  z-index: 1;
}
.variety-nav-infinite .infinite-right-nav {
  width: 15px;
  position: absolute;
  right: 25px;
  top: 35%;
  z-index: 1;
}
.variety-nav-infinite .infinite-left-nav a,
.variety-nav-infinite .infinite-right-nav a {
  display: block;
}
.variety-nav-infinite .infinite-left-nav img,
.variety-nav-infinite .infinite-right-nav img {
  width: 100%;
}

/* Variety details */
.variety-details {
  position: relative;
}

.variety-info {
  background-size: contain;
  display: flex;
  flex-wrap: wrap;
}
.variety-info::before {
  background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 40%, rgba(0,0,0,1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 40%,rgba(0,0,0,1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 40%,rgba(0,0,0,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 ); /* IE6-9 */
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.variety-info .btn-dropdown {
  margin-top: 20px;
  margin-left: 20px;
  z-index: 2;
  align-self: flex-start;
}
.variety-info .variety-nav {
  padding-top: 0;
  margin-top: 35px;
  width: 100%;
  z-index: 1;
  align-self: center;
}
.variety-info .add-to-cart-message {
  font-size: 12px;
  color: #707070;
  margin: 10px 20px;
  display: none;
}
.variety-info .add-to-cart-message.active {
  display: block;
}

.variety-attributes {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-self: flex-end;
  *position: absolute;
  position: relative;
  top: -50px;
}

.variety-name {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  z-index: 1;
}
.variety-name-title {
  font-family: 'HelveticaNeueLTStd-Cn';
  font-size: 42px; /*48px*/
  line-height: 42px;
  color: #ffffff;
}
.variety-name-family {
  font-family: 'HelveticaNeueLTStd-MdIt';
  font-size: 18px; /*22px*/
  color: #27beb7;
  line-height: 22px;
}

.variety-fx {
  background-color: rgba(39,190,183,0.6);
  border-bottom: 5px solid #26b8b4;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 20px;
  z-index: 1;
  display: none;
}
.variety-fx.active {
  display: flex;
  z-index: 0;
}
.variety-fx-data {
  width: 100%;
  align-self: stretch;
}
.variety-fx-icons {
  width: 100%;
  display: flex;
  align-self: stretch;
  justify-content: center;
}

.variety-fx-data p {
  font-family: 'HelveticaNeueLTStd-Bd';
  font-size: 28px;
  color: #ffffff;
}
.variety-fx-item {
  width: 100%;
  display: flex;
}
.variety-fx-item span {
  font-family: 'HelveticaNeueLTStd-Md';
  font-size: 14px;
  color: #ffffff;
  width: 60%;
  display: block;
  align-self: center;
  white-space: pre;
}
.variety-fx-item .fx-slider {
  background-color: #000000;
  width: 100%;
  height: 10px;
  margin-top: -5px;
  align-self: center;
}

@media screen and (max-width: 500px) {
  .variety-fx-item .fx-slider {
    width: 120px;
  }
}

.variety-fx-item .fx-slider div {
  height: 10px;
}
.variety-fx-item.effect-0 .fx-slider div {
  background-color: yellow;
}
.variety-fx-item.effect-1 .fx-slider div {
  background-color: #27beb7;
}
.variety-fx-item.effect-2 .fx-slider div {
  background-color: #ab9c7d;
}
.variety-fx-item.effect-3 .fx-slider div {
  background-color: indianred;
}


.variety-fx-icons img {
  width: 35%;
  margin-left: 10px;
  align-self: center;
}

/* Responsive */
@media screen and (max-width: 375px) {
  .variety-nav p {
    font-size: 30px;
  }
}

@media screen and (max-width: 360px) {
  .variety-nav p {
    font-size: 24px;
  }
  .variety-name-title {
    font-size: 34px;
  }
  .variety-name-family {
    font-size: 18px;
  }
  .variety-fx-data p {
    font-size: 20px;
  }
  .variety-fx-item span {
    font-size: 10px;
  }
}
