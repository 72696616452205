.reset-form-wrapper .error-msg {
	font-size: 12px;
	color: #A50203;
	height: 15px;
	margin-top: 5px;
	margin-bottom: 5px;
	margin-left: 25px;
	display: block;
}

.reset-form-wrapper input {
	font-family: 'HelveticaNeueLTStd-Md';
	font-size: 12px;
	background-color: #2c2c2c;
	color: #828290;
	width: 100%;
	padding: 10px 25px;
	padding-bottom: 8px;
	border: 0;
	border-radius: 25px;
	box-sizing: border-box;
}
.reset-form-wrapper button {
	font-family: 'HelveticaNeueLTStd-MdCn';
	font-size: 18px;
	color: #26b8b4;
	width: 100%;
	border: 1px solid #26b8b4;
	border-radius: 25px;
	padding-top: 8px;
	padding-bottom: 3px;
	margin-top: 20px;
	margin-bottom: 40px;
	background-color: transparent;
	cursor: pointer;
}
.reset-form-wrapper button:hover {
	background-color: #26b8b4;
	color: #101010;
}

.reset-form-wrapper {
	width: 75%;
	margin: 0 auto;
	margin-top: 40px;
}