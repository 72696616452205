/* Footer */
.footer {
	height: 50px;
	display: flex;
}

.footer-container {
	font-size: 12px;
	color: #707070;
	margin: auto;
	padding-left: 20px;
	padding-right: 20px;
	text-align: center;
}
.footer-container ul {
	width: 100%;
}
.footer-container li {
	display: inline;
}
.footer-container a {
	color: #707070;
	text-decoration: none;
}

/* Legal pages */
.legal-container {
	padding: 20px;
}
.legal-container h2 {
	font-family: 'HelveticaNeueLTStd-Cn';
	font-size: 22px;
	color: #a59165;
	text-transform: uppercase;
}
.legal-container table {
	border: 1px solid #707070;
	border-collapse: collapse;
	table-layout: fixed;
	width: 100%;
	max-width: 100%;
	margin-top: 20px;
	margin-bottom: 20px;
}
.legal-container table tr {
	border-bottom: 1px solid #707070;
}
.legal-container table td {
	font-size: 12px;
	border-right: 1px solid #707070;
	word-wrap: break-word;
	padding: 5px;
}
.legal-container ul {
	list-style-type: circle;
	padding-left: 40px;
	padding-right: 40px;
	margin-top: 20px;
	margin-bottom: 20px;
}
.legal-container li {
	margin-bottom: 10px;
}