.login-wrapper {
	min-height: 100vh;
	display: flex;
}
.login-container {
  margin: auto;
  width: 250px;
}
.logo-wrapper {
	text-align: center;
	margin-bottom: 40px;
  display: flex;
  justify-content: center;
}
.logo-wrapper img {
	width: 60%;
}
.login-container .error-msg {
	font-size: 12px;
	color: #A50203;
	height: 15px;
	margin-top: 5px;
	margin-bottom: 5px;
	margin-left: 25px;
	display: block;
}

.login-container a {
	font-family: 'HelveticaNeueLTStd-Md';
	display: block;
}
.login-container a.forgot-password {
	font-size: 12px;
	color: #61616b;
	text-decoration: underline;
	text-align: center;
	margin: 30px 0;
	cursor: pointer;
}

.login-container .btn {
  margin-top: 0;
}
