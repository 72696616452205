.accordion-about .accordion {
	width: 100%;
	max-width: 100%;
	padding: 20px;
	margin-bottom: 20px;
	box-sizing: border-box;
}

.accordion-about .accordion__item:after {
	border-bottom: 3px solid #27beb7;
	width: 60px;
	height: auto;
	padding-bottom: 10px;
	display: block;
	content: "";
}

.accordion-about .accordion__button {
	background-color: transparent;
	font-family: 'HelveticaNeueLTStd-Cn';
	color: #a59165;
	width: auto;
	padding-left: 0;
	padding-bottom: 0;
	outline: none;
}
.accordion-about .accordion__button:hover {
	background-color: transparent;
}
.accordion-about .accordion__button:after {
	width: 10px;
	height: 10px;
	margin-left: 12px;
	border-right: 2px solid #a59165;
	border-bottom: 2px solid #a59165;
	display: inline-block;
	content: "";
	transform: rotate(-45deg);
}
.accordion-about .accordion__button[aria-expanded='true']::after, 
.accordion-about .accordion__button[aria-selected='true']::after {
	transform: rotate(45deg);
}
.accordion-about .accordion__button:before {
	display: none;
}

.accordion-about .accordion__panel {
	padding-left: 0;
	padding-right: 0;
}
.accordion-about .accordion__panel p {
	font-size: 14px;
	color: #707070;
}
.accordion-about .accordion__panel ul {
	padding-left: 40px;
	font-size: 14px;
	color: #707070;
	list-style-type: upper-roman;
}
.accordion-about .accordion__panel li {
	margin-bottom: 10px;
}
.accordion-about .accordion__panel a {
	color: #27beb7;
}

.accordion-about h4 {
	padding-left: 20px;
	margin-bottom: 0;
}
.accordion-about p.disclaimer {
	padding-left: 20px;
	padding-right: 20px;
	margin-top: 40px;
	margin-bottom: 40px;
}
